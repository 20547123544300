<template>
  <div class="wrap">
    <div class="blue-title">
      <div class="img-wrap">
        <img src="../assets/img/qwjklogo.png" alt="" />
      </div>
      <div class="info-wrap">
        全网集客 · 让您不再缺客户
      </div>
      <div class="message">
        <span>注册账号</span>
      </div>
    </div>

    <div class="user-enter">
      <div class="com-name">
        <div class="content">
          <div><label for="copany-name">企业名称</label></div>
          <input
            type="text"
            placeholder="请输入企业名称(非必填)"
            id="copany-name"
            v-model="userComName"
          />
        </div>
      </div>
      <div class="contact">
        <div class="content">
          <div><label for="contact-name">联系人</label></div>
          <input
            type="text"
            placeholder="请输入联系人(非必填)"
            id="contact-name"
            v-model="userContact"
          />
        </div>
      </div>
      <div class="phone">
        <div class="content">
          <div>
            <label for="phone">手机号</label>
          </div>
          <input
            type="number"
            placeholder="请输入手机号"
            id="phone"
            v-model="userPhone"
            maxlength="11"
          />
        </div>
      </div>
      <div class="test">
        <div class="content  exclue">
          <div><label for="test">验证码</label></div>
          <input
            type="number"
            placeholder="请输入验证码"
            id="test"
            v-model="userTest"
            maxlength="6"
          />
          <span @click="getTest()" v-if="testFlag">获取验证码</span>
          <span class="cunting" v-if="!testFlag">{{ cunt }}秒</span>
        </div>
      </div>
      <div class="password">
        <div class="content">
          <div><label for="password">密码</label></div>
          <input
            type="password"
            placeholder="8~24位数字和字母组合"
            id="password"
            v-model="userPassword"
          />
        </div>
      </div>
    </div>

    <div class="confirm">
      <button @click="logOn()">注册并加入团队</button>
    </div>
    <div class="backto"><span @click="goback()">返回登录</span></div>
  </div>
</template>

<script>
import { getVerificode } from "../util/crypto";
export default {
  name: "shareregister",
  methods: {
    goback() {
      // 返回sharelog
      this.$router.back();
    },
    getTest() {
      if (this.userPhone === "" || this.userPhone.length < 11) {
        this.$message({
          message: "请正确填写手机号",
          type: "error",
          offset: "300",
        });
      } else {
        this.$http({
          method: "post",
          url: "/api/verify/sendsmscode",
          data: {
            app_type: "qwjk",
            verificode: getVerificode(),
            mobile: this.userPhone,
            check: "exist",
          },
        }).then((res) => {
          if (res.data.code == "0") {
            this.testFlag = false;
            this.cunt = 60;
            let timer = setInterval(() => {
              this.cunt--;
              if (this.cunt === 0) {
                this.testFlag = true;
                clearInterval(timer);
              }
            }, 1000);
            this.$message({
              message: "验证码已成功发送",
              type: "success",
              offset: "300",
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: "error",
              offset: "300",
            });
          }
        });
      }
      // 获取验证码
      //
    },
    logOn() {
      if (this.userPhone === "") {
        this.$message({
          message: "请输入手机号",
          type: "error",
          offset: "300",
        });
      } else if (this.userTest === "") {
        this.$message({
          message: "请输入验证码",
          type: "error",
          offset: "300",
        });
      } else if (this.userPassword === "") {
        this.$message({
          message: "密码不能为空",
          type: "error",
          offset: "300",
        });
      } else if (
        this.userPassword.length < 8 ||
        this.userPassword.length > 24
      ) {
        this.$message({
          message: "密码的长度必须在8-24位之间",
          type: "error",
          offset: "300",
        });
      } else {
        this.$http({
          method: "post",
          url: "/api/login/regist",
          data: {
            app_type: "qwjk",
            verificode: getVerificode(),
            company: this.userComName,
            contact: this.userContact,
            mobile: this.userPhone,
            password: this.userPassword,
            verify: this.userTest,
            type: this.type,
            referrer: this.referrer,
          },
        }).then((res) => {
          let data = res.data;
          console.log(data);
          if (data.code != 0) {
            this.$message({
              message: data.msg,
              type: "error",
              offset: "300",
            });
          } else if (data.code == 0) {
            this.$router.push("/success/");
          }
        });
      }
    },
  },
  data() {
    return {
      userPhone: "",
      userTest: "",
      userPassword: "",
      testFlag: true,
      //验证码决定flag
      cunt: 1,
      // 计数器
      link: null,
      //绑定链接
      type: null,
      referrer: null,
      userComName: "",
      userContact: "",
    };
  },
  created() {
    // work
    this.type = this.$route.query.type;
    this.referrer = this.$route.query.referrer;
  },
};
</script>

<style lang="less" scoped>
.wrap {
  width: 750px;
  height: 100vh;
  background-color: #ffffff;
  .blue-title {
    position: relative;
    padding-top: 56px;
    width: 750px;
    height: 400px;
    background: #3e62f1;
    .img-wrap {
      display: flex;
      justify-content: center;
      img {
        height: 181px;
      }
    }
    .info-wrap {
      padding-top: 29px;
      display: flex;
      justify-content: center;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #f7f7f9;
      opacity: 0.8;
    }
    .message {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      bottom: -5px;
      left: 30px;
      width: 690px;
      height: 76px;
      background: #ffffff;
      border-radius: 4px;

      span {
        font-size: 32px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #343538;
      }
    }
  }

  .user-enter {
    margin-top: 20px;
    width: 750px;
    height: 530px;
    background: #ffffff;
    .phone,
    .test,
    .password,
    .com-name,
    .contact {
      height: 105px;
      width: 750px;
      // background-color: rgb(187, 219, 238);
      .content {
        display: flex;
        align-items: center;
        width: 670px;
        margin-left: 45px;
        height: 105px;
        border-bottom: 1px solid #dee0e3;
        div {
          font-size: 32px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #343638;
          width: 180px;
        }
        input {
          height: 65px;
          width: 350px;
          border: 0;
          font-size: 30px;
          font-family: PingFang SC;
          font-weight: 500;
        }
        .cunting {
          color: rgb(148, 146, 146);
        }
      }
      .exclue {
        border-bottom: 0;
      }
    }
  }
  .test {
    span {
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #3e62f1;
      line-height: 42px;
    }
  }
  .confirm {
    margin-top: 15px;
    width: 690px;
    height: 90px;
    margin: 0 auto;
    margin-top: 20px;
    button {
      width: 690px;
      height: 90px;
      background: #3e62f1;
      border-radius: 8px;
      border: 0;
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
  input:focus {
    outline: none;
  }
  .golog {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    margin: 0 auto;
    margin-top: 78px;
    margin-top: 98px;
    font-size: 25px;
    color: #3e62f1;
  }

  .backto {
    margin-top: 131px;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #3e62f1;
    display: flex;
    justify-content: center;
  }
}
</style>
